<template>
    <div class="app-body monthCard_re" id="monthCard_re">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">月卡关系管理</span>
        </div>
        <el-card class="el-main sceneCheckContent">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <!-- <le-input label="实体卡号" v-model="pageParam.params.cardNo" /> -->
                <jd-select-input :value.sync="searchVal1.value" :selectValue.sync="searchVal1.select" :selectOptions="selectOptions1"></jd-select-input>
                <le-input label="用户手机号" v-model="pageParam.params.phone" />
                <le-input label="月卡ID" v-model="pageParam.params.monthcardId" />
                <le-input label="月卡名称" v-model="pageParam.params.monthcardName" />
                <!-- <le-input label="站点名称" v-model="pageParam.params.stationName" /> -->
                <jd-select-input :value.sync="searchVal2.value" :selectValue.sync="searchVal2.select" :selectOptions="selectOptions2"></jd-select-input>
                <div class="a-flex-rfsc">
                    <el-select v-model="pageParam.params.dateType" style="width:100px;margin-left:24px">
                        <el-option label="开始时间" :value="1"></el-option>
                        <el-option label="失效时间" :value="2"></el-option>
                    </el-select>
                    <le-date-range :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" />
                </div>
            </le-search-form>
            <le-pagview ref="monthCardRealationPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.monthCardRealation">
                <el-table ref="monthCardRealation" 
                    :data="tableData" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    :highlight-current-row="true"  
                    style="width: 100%" 
                    @expand-change="expandChange" 
                    @filter-change='filterFun'>
                    <el-table-column type="expand" >
                        <template slot-scope="props">
                            <div>
                                <el-form label-position="left" :style="'margin-left:'+moreInfoLeft" v-if="oparateData[props.row.id]&&oparateData[props.row.id].length">
                                    <el-form-item label="" v-for="(item,index) in oparateData[props.row.id]" :key="index">
                                        <span>{{ item.createTime }}，</span>
                                        <span>用户{{ item.phone }}</span>
                                        <span>{{ item.appidDesc }}</span>
                                        <span>{{ item.operateType | initDic(monthcardOperateDic) }}。</span>
                                        <span v-if="item.serviceStartTime">套餐开始时间{{ item.serviceStartTime }}，</span>
                                        <span v-if="item.serviceEndTime">套餐结束时间{{ item.serviceEndTime }}，</span>
                                        <span v-if="item.cardNo">绑定卡号{{ item.cardNo }}，</span>
                                        <span v-if="item.rfcardNo">绑定RFID卡号{{ item.rfcardNo }}，</span>
                                        <span v-if="item.orderSn">订单号{{ item.orderSn }}。</span>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="80" ></el-table-column>
                    <el-table-column prop="phone" label="用户手机号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.phone?row.phone:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="monthcardId" label="月卡ID" min-width="120"></el-table-column>
                    <el-table-column prop="monthcardName" label="月卡名称" min-width="120"></el-table-column>
                    <el-table-column prop="" label="卡类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.type | initDic(cardTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buyStationName" label="购买站点" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.buyStationName?row.buyStationName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buyStationId" label="购买站点ID" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.buyStationId?row.buyStationId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="statusText" 
                        min-width="140"
                        label="当月剩余充电时长">
                        <template slot-scope="{ row }">
                            <span v-if="row.usingDuration == -1">不限制</span>
                            <span v-else>{{ row.remainDuration || row.remainDuration===0?row.remainDuration+'分钟/本月':'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationSize" label="剩余开门次数" min-width="120">
                        <template slot-scope="{ row }">
                            <span v-if="row.parkTotalTimes==-1">不限制</span>
                            <span v-else>{{ (row.parkTotalTimes-row.parkUsingTimes>=0?row.parkTotalTimes-row.parkUsingTimes:0)+'次/本日'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bindCardNo" label="实体卡号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.bindCardNo?row.bindCardNo:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="mstate" 
                        key="mstate"
                        min-width="120"
                        column-key="state"
                        :filter-multiple='false'
                        :filters="stateDic"
                        label="状态">
                        <template slot="header">
                            <div class="a-flex-rfsc">
                                <span>{{ pageParam.params.state | initTablelabel(stateDic,'状态') }}</span>
                                <img style="width:15px;height:15px;margin-left:7px" src="../../assets/icon/filter-icon.png" alt="">
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.state | initDic(stateDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bindRfCardNo" label="RFID" min-width="180"></el-table-column>
                    <el-table-column prop="createTimeText" label="开始时间" min-width="180"></el-table-column>
                    <el-table-column prop="expireTimeText" label="失效时间" min-width="180"></el-table-column>
                    <el-table-column prop="name" label="操作" width="120" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="操作记录" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="operateRecord(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="修改有效期" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="addMonthCardDate(scope.row)" v-if="company.isSystem == 1 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更新套餐权限" placement="top">
                                <img src="../../assets/icon/option-history.png" class="a-wh-16" @click="updateMonthAuth(scope.row,1)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperation">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command='"3#"+scope.$index' v-if="!scope.row.bindCardNo">绑定实体卡</el-dropdown-item>
                                        <el-dropdown-item :command='"1#"+scope.$index' v-if="scope.row.bindCardNo">解绑实体卡</el-dropdown-item>
                                        <el-dropdown-item :command='"5#"+scope.$index' v-if="!scope.row.bindRfCardNo">绑定RFID卡</el-dropdown-item>
                                        <el-dropdown-item :command='"4#"+scope.$index' v-if="scope.row.bindRfCardNo">解绑RFID卡</el-dropdown-item>
                                        <el-dropdown-item :command='"2#"+scope.$index' >解绑月卡</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
<!-- 
                            <el-tooltip class="item" effect="dark" content="解绑实体卡" placement="top">
                                <img src="../../assets/icon/userMGT-unbind.png" class="a-wh-16" @click="unBindRealCard(scope.row)" />
                            </el-tooltip> 
                            
                            <el-tooltip class="item a-ml-12" effect="dark" content="解绑月卡" placement="top">
                                <img src="../../assets/icon/unbind.png" class="a-wh-16" @click="unBindCard(scope.row)" />
                            </el-tooltip> -->
                            
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
        <add-expiretime ref="addExpire" @updatePage="pageParam.freshCtrl++"></add-expiretime>
    </div>
</template>

<script>
import addExpiretime from './child/addExpiretime.vue';
import { mapState } from 'vuex';
    export default {
  components: { addExpiretime },
        data () {
            return {
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getUserMonthcardPage,
                    method: "post",
                    params: {
                        phone: '',
                        monthcardId: '',
                        monthcardName: '',
                        cardNo: '',
                        rfCardNo: '',
                        state: '',
                        stationName: '',
                        stationId: '',
                        startDate: '',
                        endDate: '',
                        dateType: 1,
                    },
                    freshCtrl: 1,
                },
                fileUrl: '',
                cardTypeDic: [],//卡类型字典
                isTempcardDic: [],//是否临时卡字典
                isSystemDefaultDic: [],//是否系统默认卡字典
                activeCardDic: [],//激活模式字典
                needPostDic: [],//是否邮寄
                showModelDic: [],//可见模式字典
                stateDic: [],//状态字典
                timeTypeDic: [],
                monthcardOperateDic: [],//操作类型字典
                moreInfoLeft: '16px',
                oparateData: {},//操作记录
                searchVal1: {
                    value: '',
                    select: '1'
                },
                selectOptions1: [{
                    label: '实体卡号',
                    value: '1'
                },{
                    label: 'RFID',
                    value: '2'
                }],
                searchVal2: {
                    value: '',
                    select: '1'
                },
                selectOptions2: [{
                    label: '站点名称',
                    value: '1'
                },{
                    label: '站点ID',
                    value: '2'
                }]
            }
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
                userInfo: state => state.user.user,
            })
        },
        created () {
            this.$getDic('monthcardType').then(res=>{ this.cardTypeDic = res; })
            this.$getDic('isTempcard').then(res=>{ this.isTempcardDic = res; })
            this.$getDic('isSystemDefault').then(res=>{ this.isSystemDefaultDic = res; })
            this.$getDic('activeCard').then(res=>{ this.activeCardDic = res; })
            this.$getDic('needPost').then(res=>{ this.needPostDic = res; })
            this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
            this.$getDic('timeType').then(res=>{ this.timeTypeDic = res; })
            this.$getDic('userMonthcardState').then(res=>{ this.stateDic = res; })
            this.$getDic('monthcardOperate').then(res=>{ this.monthcardOperateDic = res; })
            
        },
        mounted () {
            this.$eventBus.$on('tableScroll', (res) => {
                this.moreInfoLeft = res + 16 + 'px'
            })
            console.log(this.userInfo);
        },
        activated () {
            this.pageParam.freshCtrl++
        },  
        destroyed() {
            this.$eventBus.$off('tableScroll')
        },
        watch:{
            searchVal1:{
                deep: true,
                immediate:true,
                handler (val) {
                    this.pageParam.params.cardNo = val.select==1?val.value:''
                    this.pageParam.params.rfCardNo = val.select==2?val.value:''
                }     
            },
            searchVal2:{
                deep: true,
                immediate:true,
                handler (val) {
                    this.pageParam.params.stationName = val.select==1?val.value:''
                    this.pageParam.params.stationId = val.select==2?val.value:''
                }     
            }
        },
        methods: {
            //获取列表
            setTableData(data,fileUrl) {
                this.tableData = data;
                this.fileUrl = fileUrl
            },
            handlerRest() {
                this.searchVal1 = {
                    value: '',
                    select: '1'
                }
                this.pageParam.params = {
                    phone: '',
                    monthcardId: '',
                    monthcardName: '',
                    cardNo: '',
                    rfCardNo: '',
                    state: '',
                    stationName: '',
                    stationId: '',
                    startDate: '',
                    endDate: '',
                    dateType: 1,
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['monthCardRealationPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handlerOperation (commands) {
                let command = commands.split('#')[0]
                let index = commands.split('#')[1]
                switch (command) {
                    case '1':
                        this.unBindRealCard(this.tableData[index])
                        break;
                    case '2':
                        this.unBindCard(this.tableData[index])
                        break;
                    case '3':
                        this.bindCard(this.tableData[index])
                        break;
                    case '4':
                        this.unBindRfCard(this.tableData[index])
                        break;
                    case '5':
                        this.bindRfCard(this.tableData[index])
                        break;
                    default:
                        break;
                }
            },
            unBindRealCard (datas) {
                this.$confirm('是否解除实体卡绑定？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.unbindCard,
                        method: "post",
                        params: {
                            userMonthcardId: datas.id,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            },
            bindCard (datas) {
                this.$prompt('', '绑定实体卡', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValidator: (val)=>{
                        if(!val){
                            return '请输入实体卡号'
                        }
                    }
                }).then(({ value }) => {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.bindCard,
                        method: "post",
                        params: {
                            userMonthcardId: datas.id,
                            cardNo: value
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        }else{
                            this.$message.error(res.result.message)
                        }
                    }).catch(()=>{ })
                }).catch(() => {});
            },
            unBindRfCard (datas) {
                this.$confirm('是否解除RFID卡绑定？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.unbindRfCard,
                        method: "post",
                        params: {
                            userMonthcardId: datas.id,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            },
            bindRfCard (datas) {
                this.$prompt('', '绑定RFID卡', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValidator: (val)=>{
                        if(!val){
                            return '请输入RFID卡号'
                        }
                    }
                }).then(({ value }) => {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.bindRfCard,
                        method: "post",
                        params: {
                            userMonthcardId: datas.id,
                            cardNo: value
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        }else{
                            this.$message.error(res.result.message)
                        }
                    }).catch(()=>{ })
                }).catch(() => {});
            },
            unBindCard (datas) {
                this.$confirm('是否解除月卡关系？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.unbindMonthcard,
                        method: "post",
                        params: {
                            userMonthcardId: datas.id,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            },
            addMonthCardDate (row) {
                this.$refs['addExpire'].dialogVisible = true
                this.$refs['addExpire'].expireTime = row.expireTime
                this.$refs['addExpire'].form.expireTime = row.expireTime
                this.$refs['addExpire'].form.userMonthCardId = row.id
            },
            updateMonthAuth (row, operation) {
                this.$confirm('是否确认更新套餐权限？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.updateMonthAuthApi(row, operation)
                }).catch(_=>{ })
            },
            updateMonthAuthApi (row, operation) {
                let expireTime = ''
                if(operation == 1){
                    expireTime = row.expireTime + (1 * 24 * 60 * 60 * 1000)
                }else if(operation == 2){
                    expireTime = row.expireTime - (1 * 24 * 60 * 60 * 1000)
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.addUserMonthCardTime,
                    method: "post",
                    params: {
                        userMonthCardId: row.id,
                        day: 1,
                        expireTime: expireTime,
                        operation: operation
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        if(operation == 1) {
                            this.updateMonthAuthApi(row, 2)
                        }else {
                            this.$message.success('操作成功')
                        }
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            expandChange (row,expandedRows) {
                this.$Axios._get({
                    url: this.$Config.apiUrl.userMonthcardOperate,
                    method: "get",
                    params: {
                        usermonthcardId: row.id,
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$nextTick(()=>{
                            this.$set(this.oparateData,row.id,res.result.data)
                            // this.oparateData[row.id] = res.result.data
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            operateRecord (row){
                this.$refs['monthCardRealation'].toggleRowExpansion(row)
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='state'){
                        if(value[key].length==0){
                            this.pageParam.params.state = ''
                        }else{
                            this.pageParam.params.state = value[key][0]
                        }
                    }
                }
                this.handlerSearch()
            },
            initParkTimes (datas) {
                if(datas.parkTotalTimes == -1){
                    return '不限制'
                }else{
                    if(datas.parkLimitType == 1){
                        return datas.parkTotalTimes - datas.parkUsingTimes
                    }else{
                        let timeText = this.timeTypeDic.find(res=>{
                            return res.value == datas.parkTimeType
                        })
                        let txt = timeText?'本' + timeText.text:''
                        return (txt + ' ' ) + (datas.parkTotalTimes - datas.parkUsingTimes)
                    }
                }
            },  
            initChargeTimes (datas) {
                if(datas.parkTotalTimes == -1){
                    return '不限制'
                }else{
                    if(datas.parkLimitType == 1){
                        return datas.parkTotalTimes - datas.parkUsingTimes
                    }else{
                        let timeText = this.timeTypeDic.find(res=>{
                            return res.value == datas.parkTimeType
                        })
                        let txt = timeText?'本' + timeText.text:''
                        return (txt + ' ' ) + (datas.parkTotalTimes - datas.parkUsingTimes)
                    }
                }
            },  
            exportfile () {
                if(!this.fileUrl) {
                    this.$message.warning('暂无可导出文件')
                    return
                }
                this.$confirm('是否确认导出文件？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Utils.downloadUrlFile(this.fileUrl, '省份经营数据列表')
                }).catch(_=>{ })
            }
        }
    }
</script>

<style lang="scss" scoped>
.monthCard_re{
    .s-btn-add{
        width: 135px;
    }
}
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>